import React from "react";

import Babajide from "../../../../../../assets/img/testimonials/babajide.svg"
import Adebambo from "../../../../../../assets/img/testimonials/adebambo.svg"
import Ifeanyichulwu from "../../../../../../assets/img/testimonials/Ifeanyichulwu.svg"
import RealSOK from "../../../../../../assets/img/testimonials/realsok.svg"
import {TestimonialStyle} from '../styles/testimonialStyle'
import { Img } from "react-image";

const Testimonials = () => {
 
    const testimonials = [
        {
          text: (<span>Well done to the guys at <span className="text-bold">@kudabank</span> had a dispensing error on Friday night and reached out to them on Sunday... 48 working hours after and my money is back in my account... Special shout out to Maxwell and Norah</span>),
          name: "Babajide Duroshola",
          icon: Babajide
        },
        {
          text: (
            <p>
              Just joined the best Digital Bank in Nigeria<br />
              I have no complaints whatsoever since i started using <span className="text-bold">Kuda</span>
            </p>
          ),
          name: "@RealSOK_",
          icon: RealSOK
        },
        {
          text: (
            <p>
              Never thought an app would stylish become my everyday way to bank and have access to physical cash.
              <br /> <span className="text-bold">@kudabank </span>is sleek
            </p>
          ),
          name: "Adebambo Oyekan",
          icon: Adebambo
        },
        {
          text: (
            <p>
             This is the best banking app! I’m enjoying seamless banking plus the free transfers to other banks is a thing of joy. This is what I call freedom. I’ve made kuda my main bank account and I’m gonna refer all my friends!
            </p>
          ),
          name: "Ifeanyichukwu Obaji",
          icon: Ifeanyichulwu
        },
      ]
    return (
      <TestimonialStyle>
        <div className="kuda-section kuda-ignore--container" >
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <div className="kuda-testimonial--wrap">
                        <h1 className="kuda-section--heading text-center text-xlbold color-black title-bottom--spacing">
                            Don’t just take our word for it
                        </h1>
                        <div className="kuda-testimonials--section">
                            <div className="kuda-testimonial--cards">
                                {testimonials.map((item, i) =>
                                    <div key={i} className="card card-item flex flex-column justify-between">
                                        <p className="testimonial-text color-black text-sm">{item.text}</p>
                                        <div className="testimonial-extras flex align-center">
                                            {item.icon !== "" ?
                                                <Img src={item.icon} alt="kuda Microfinance Bank Testimonials" />
                                                :
                                                <div className="kuda-no--image">
                                                    {item.name.charAt(0)}
                                                </div>
                                            }

                                            <p className="text-sm text-xlbold color-black">{item.name}</p>
                                        </div>

                                    </div>)
                                }
                                {testimonials.map((item, i) =>
                                    <div key={i} className="card card-item flex flex-column justify-between">
                                        <p className="testimonial-text color-black text-sm">{item.text}</p>
                                        <div className="testimonial-extras flex align-center">
                                            {item.icon !== "" ?
                                                <Img src={item.icon} alt="kuda Microfinance Bank Testimonials" />
                                                :
                                                <div className="kuda-no--image">
                                                    {item.name.charAt(0)}
                                                </div>
                                            }
                                            <p className="text-sm text-xlbold color-black">{item.name}</p>
                                        </div>

                                    </div>)
                                }
                            </div>
                        </div>

                    </div>
                </div>
               
            </div>

        </div>
        </TestimonialStyle>
    )
}

export default Testimonials;
